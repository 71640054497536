import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
  Avatar,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownOutlined,
  FormOutlined,
  SlidersOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import isNull from 'lodash/isNull';
import {
  CONNECTOR_LIST,
  CONNECTOR_SINGLE_DATA,
  CONNECTOR_TYPE_LIST,
} from '../../../constants/api';
import { patchAPI, postAPI } from '../../../utils/apiRequest';
import { checkIntegrationButton, interpolate } from '../../../utils/common';
import {
  ACTIVE_INACTIVE_FILTER_LIST,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  DESCRIPTION_PARAGRAPH_ELLIPSIS,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import ModalCom from '../../common/Modal';
import SyncConnectorTabIntgeration from './SyncConnectorTabIntegration';

const { Title, Paragraph } = Typography;

function SyncConnectorManagement(props) {
  const [toggleEditConnectorModal, setToggleEditConnectorModal] =
    useState(false);
  const [connectorData, setConnectorData] = useState({});
  const [connectorTypeData, setConnectorTypeData] = useState({});
  const [connectorDataLoading, toggleConnectorDataLoading] = useState(false);
  const [connectorDataActionLoading, toggleConnectorDataActionLoading] =
    useState(false);
  const [integrationId, setIntegrationId] = useState(-1);
  const [intSyncConnectorId, setIntSyncConnectorId] = useState(-1);
  const [integrationDrawer, setIntegrationDrawer] = useState(false);
  const tablePaginationParams = useSelector(
    (state) =>
      _get(state, 'global.globalPagesPagination')[PAGES.SYSTEM_SYNC_CONNECTORS]
  );
  const toggleFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterToggle')[
        PAGES.SYSTEM_SYNC_CONNECTORS
      ]
  );
  const activeFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterData')[PAGES.SYSTEM_SYNC_CONNECTORS]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(
          PAGES.SYSTEM_SYNC_CONNECTORS,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.SYSTEM_SYNC_CONNECTORS, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterToggle(
          PAGES.SYSTEM_SYNC_CONNECTORS,
          param(toggleFilters)
        )
      );
    } else {
      dispatch(globalPagesFilterToggle(PAGES.SYSTEM_SYNC_CONNECTORS, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterData(
          PAGES.SYSTEM_SYNC_CONNECTORS,
          param(activeFilters)
        )
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.SYSTEM_SYNC_CONNECTORS, param));
    }
  };
  useEffect(() => {
    loadConnectorsData();
  }, [tablePaginationParams]);
  useEffect(() => {
    loadConnectorsTypeData();
  }, []);
  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      ellipsis: true,
      width: 50,
      render: (value, record) => (
        <Space>
          <Tooltip title={'Edit'}>
            <FormOutlined onClick={() => setToggleEditConnectorModal(record)} />
          </Tooltip>{' '}
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sorter: true,
      align: 'right',
      ellipsis: true,
      width: 60,
      sortOrder:
        _get(connectorData, 'sortBy') === 'id'
          ? _get(connectorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      width: 150,
      sortOrder:
        _get(connectorData, 'sortBy') === 'name'
          ? _get(connectorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value, record) => (
        <Space>
          {!isNull(_get(record, 'SyncConnectorType.image_url')) ? (
            <Avatar
              size='default'
              src={_get(record, 'SyncConnectorType.image_url')}
            />
          ) : (
            <Avatar style={{ backgroundColor: '#FAA500' }} size='large'>
              {' '}
              {value ? value.charAt(0) : '-'}
            </Avatar>
          )}
          {value || '-'}
        </Space>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      ellipsis: true,
      width: 150,
      render: (value, record) =>
        value ? (
          <Paragraph
            ellipsis={{ ...DESCRIPTION_PARAGRAPH_ELLIPSIS, tooltip: value }}
            style={{ maxWidth: 260 }}
            key={record.id}
          >
            {value}
          </Paragraph>
        ) : (
          '-'
        ),
    },
    {
      title: 'Markup Percent',
      key: 'markup_percent',
      dataIndex: 'markup_percent',
      sorter: true,
      align: 'right',
      ellipsis: true,
      width: 120,
      sortOrder:
        _get(connectorData, 'sortBy') === 'markup_percent'
          ? _get(connectorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Require Labor',
      key: 'require_labor',
      dataIndex: 'require_labor',
      ellipsis: true,
      width: 100,
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={connectorDataActionLoading}
          key={`${record.id}-labor`}
          onClick={() => updateConnector(record.id, { require_labor: !value })}
        />
      ),
    },
    {
      title: 'Sync Connector Type',
      key: 'sync_connector_type_id',
      dataIndex: 'sync_connector_type_id',
      ellipsis: true,
      width: 150,
      render: (value, record) => _get(record, 'SyncConnectorType.name', '-'),
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'is_active',
      ellipsis: true,
      width: 100,
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={connectorDataActionLoading}
          key={`${record.id}-active`}
          onClick={() => updateConnector(record.id, { is_active: !value })}
        />
      ),
    },
    {
      title: '',
      key: 'integration',
      dataIndex: 'action_integration',
      ellipsis: true,
      width: 120,
      render: (value, record) =>
        checkIntegrationButton(parseInt(record.sync_connector_type_id)) ? (
          <Button
            type={!record.setup_done ? 'link' : 'dashed'}
            style={{
              borderColor: !record.setup_done ? '#4096ff' : '#76BC2D',
              color: !record.setup_done ? '#4096ff' : '#76BC2D',
              borderRadius: '8px',
            }}
            onClick={() => {
              if (record.setup_done === false) {
                window.open(record.auth_url, '_blank');
              } else {
                setIntegrationId(record.id);
                setIntSyncConnectorId(record.sync_connector_type_id);
                setIntegrationDrawer(true);
              }
            }}
          >
            Integration
          </Button>
        ) : (
          ''
        ),
    },
  ];
  const loadConnectorsData = () => {
    toggleConnectorDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setConnectorData(result);
      toggleConnectorDataLoading(false);
    };
    let errorFn = function () {
      toggleConnectorDataLoading(false);
    };
    postAPI(CONNECTOR_LIST, params, successFn, errorFn);
  };
  const loadConnectorsTypeData = () => {
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setConnectorTypeData(result);
    };
    let errorFn = function () {};
    postAPI(CONNECTOR_TYPE_LIST, params, successFn, errorFn);
  };
  const updateConnector = (id, data) => {
    let reqData = {
      ...data,
      markup_percent: data.markup_percent ? +data.markup_percent : undefined,
      name: undefined,
      sync_connector_type_id: undefined,
      description: undefined,
    };

    toggleConnectorDataActionLoading(true);
    let successFn = function () {
      toggleConnectorDataActionLoading(false);
      setToggleEditConnectorModal(false);
      loadConnectorsData();
    };
    let errorFn = function () {
      toggleConnectorDataActionLoading(false);
    };
    patchAPI(
      interpolate(CONNECTOR_SINGLE_DATA, [id]),
      reqData,
      successFn,
      errorFn
    );
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };
  return (
    <>
      <Title className={'page-title'} level={2}>
        Sync Connectors
      </Title>
      <Table
        className='data-table full-height-table'
        size={'small'}
        scroll={{ y: window.innerHeight - 450 }}
        rowKey={'id'}
        pagination={false}
        dataSource={_get(connectorData, 'data', [])}
        onChange={(a, b, sorting) =>
          setTableSortingParams(sorting.field, sorting.order)
        }
        columns={tableColumns}
        showSorterTooltip={false}
        loading={connectorDataLoading}
        title={() => (
          <Row justify={'end'}>
            <Col>
              <Button
                size={'large'}
                onClick={() => setToggleFilters(!toggleFilters)}
              >
                {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
              </Button>
              <Button
                size={'large'}
                type={'secondary'}
                icon={<SyncOutlined />}
                onClick={loadConnectorsData}
              >
                Refresh
              </Button>
            </Col>
            {toggleFilters && (
              <Col span={24}>
                <Form
                  className='filter-form'
                  onFinish={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset: 0,
                      filters: { ...activeFilters },
                    })
                  }
                >
                  <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                    <Input
                      value={activeFilters.name}
                      placeholder='Name'
                      onChange={(e) =>
                        setActiveFilters((prev) => {
                          return { ...prev, name: e.target.value };
                        })
                      }
                    />
                    <Select
                      optionFilterProp='children'
                      showSearch
                      value={activeFilters.sync_connector_type_id}
                      placeholder={'Sync Connector Type'}
                      style={{ width: 200 }}
                      allowClear
                      onChange={(value) =>
                        setActiveFilters((prev) => {
                          return { ...prev, sync_connector_type_id: value };
                        })
                      }
                    >
                      {_get(connectorTypeData, 'data', []).map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      optionFilterProp='children'
                      showSearch
                      value={activeFilters.require_labor}
                      placeholder={'Require Labor'}
                      style={{ width: 200 }}
                      allowClear
                      onChange={(value) =>
                        setActiveFilters((prev) => {
                          return { ...prev, require_labor: value };
                        })
                      }
                    >
                      {ACTIVE_INACTIVE_FILTER_LIST.map((item) => (
                        <Select.Option value={item.value} key={item.label}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      optionFilterProp='children'
                      showSearch
                      value={activeFilters.is_active}
                      placeholder={'Status'}
                      style={{ width: 200 }}
                      allowClear
                      onChange={(value) =>
                        setActiveFilters((prev) => {
                          return { ...prev, is_active: value };
                        })
                      }
                    >
                      {ACTIVE_INACTIVE_FILTER_LIST.map((item) => (
                        <Select.Option value={item.value} key={item.label}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        Apply
                      </Button>
                    </Form.Item>
                    <Button
                      type={'secondary'}
                      onClick={() => {
                        setActiveFilters({});
                        setTablePaginationParams({
                          ...tablePaginationParams,
                          filters: {},
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form>
              </Col>
            )}
          </Row>
        )}
        footer={() => (
          <Row justify={'space-between'}>
            <Col>
              <Space>
                <Dropdown
                  menu={{ items: setDropDownItems() }}
                  trigger={['click']}
                >
                  <Button type='ghost'>
                    Items Per Page:{_get(tablePaginationParams, 'limit')}
                    <DownOutlined className={'ml-5'} />
                  </Button>
                </Dropdown>
                <Typography>{`${_get(connectorData, 'offset', 0) + 1} to ${
                  _get(connectorData, 'data', []).length +
                  _get(connectorData, 'offset', '--')
                } of ${_get(connectorData, 'total', '--')} items`}</Typography>
              </Space>
            </Col>
            <Col>
              <Button.Group>
                <Button
                  type='ghost'
                  icon={<CaretLeftOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(connectorData, 'offset', 0) -
                        _get(connectorData, 'limit', 0),
                    })
                  }
                  disabled={!_get(connectorData, 'offset', 0)}
                />
                <Button
                  type='ghost'
                  icon={<CaretRightOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(connectorData, 'offset', 0) +
                        _get(connectorData, 'limit', 0),
                    })
                  }
                  disabled={
                    _get(connectorData, 'offset', 0) +
                      _get(connectorData, 'limit', 0) >=
                    _get(connectorData, 'total', 0)
                  }
                />
              </Button.Group>
            </Col>
          </Row>
        )}
      />
      <ModalCom
        open={toggleEditConnectorModal}
        title={'Edit Connector'}
        onCancel={() => setToggleEditConnectorModal(false)}
        footer={null}
        key={_get(toggleEditConnectorModal, 'id')}
      >
        <Form
          layout='vertical'
          key={_get(toggleEditConnectorModal, 'id')}
          initialValues={{ ...toggleEditConnectorModal }}
          onFinish={(values) =>
            updateConnector(_get(toggleEditConnectorModal, 'id'), values)
          }
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='Name' name='name'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Markup Percent' name='markup_percent'>
                <InputNumber className={'right-aligned'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Description' name='description'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Sync Connector Type'
                name='sync_connector_type_id'
              >
                <Select optionFilterProp='children' showSearch disabled={true}>
                  <Select.Option
                    value={_get(
                      toggleEditConnectorModal,
                      'SyncConnectorType.id'
                    )}
                  >
                    {_get(toggleEditConnectorModal, 'SyncConnectorType.name')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Require Labor'
                name='require_labor'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Active'
                name='is_active'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={connectorDataActionLoading}
                >
                  {' '}
                  Update{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => setToggleEditConnectorModal(false)}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      <SyncConnectorTabIntgeration
        id={integrationId}
        syncConnectorTypeId={intSyncConnectorId}
        open={integrationDrawer}
        onClose={() => setIntegrationDrawer(false)}
      />
    </>
  );
}

export default SyncConnectorManagement;
